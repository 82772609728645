.cancel-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
  margin-bottom: 10px;
}

.tax-benefit-container {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  /* background-color: red; */
  padding: 00.75rem;
}

.tax-benefit-para {
  flex: 1;
  /* background-color: green; */
  margin-top: 35px;
  color: #fafafa;
}

.tax-benefit-para1 {
  color: #c8c8c8;
  margin: 20px 10px 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 0.7rem;
}

.tax-benefit-img1 {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tax-close-icon {
  position: relative;
  /* height: 2rem; */
  float: right;
  /* right: 13px; */
  cursor: pointer;
  /* margin-top: 20px; */
}

.tax-benefit-img {
  flex: 5;
  /* background-color: red; */
  /* background-color: red; */
  display: flex;
  flex-direction: column;
}
.tax-benefit-img div {
  align-self: center;
  justify-content: center;
  vertical-align: middle;
  align-content: center;
  position: relative;
  /* margin: auto; */
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 375px) {
  .tax-benefit-img img {
    max-width: 200px;
  }
}
